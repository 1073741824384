<script setup>
import { useDisplay } from 'vuetify'
import { ref } from 'vue'

const { mobile } = useDisplay()

defineProps({
  data: {
    type: Object,
    required: true,
  },
})

const paneles = [
  {
    title: 'Nosotros',
    value: 'nosotros',
  },
  {
    title: 'Servicios',
    value: 'servicios',
  },
  {
    title: 'Contacto',
    value: 'contacto',
  },
]
const panelActivo = ref('nosotros')
const drawer = ref(!mobile.value)
const subMenu = ref(false)
</script>

<template>
  <Head :title="data.nombre" />
  <v-app class="bg-grey-lighten-4">
    <v-app-bar color="green">
      <v-app-bar-nav-icon variant="text" @click.stop="drawer = !drawer" v-if="mobile"></v-app-bar-nav-icon>
      <v-toolbar-title v-if="mobile">{{ data.nombre }}</v-toolbar-title>
      <v-container class="d-flex" v-if="!mobile">
        <v-row align="center">
          <v-tabs v-model="panelActivo" bg-color="green">
            <v-tab v-for="panel in paneles" :key="panel.value" :value="panel.value">{{ panel.title }}</v-tab>
          </v-tabs>
          <div class="ml-auto">
            <v-menu>
              <template v-slot:activator="{ props }">
                <v-btn v-bind="props">Ingresar</v-btn>
              </template>
              <v-list :items="['Administrador', 'Cliente']" class="py-0">
                <template v-slot:item="{ props }">
                  <Link :href="route('login', { tipo: props.title.toLowerCase() })" as="div">
                    <v-list-item class="subMenu" v-bind="props"></v-list-item>
                  </Link>
                </template>
              </v-list>
            </v-menu>
          </div>
        </v-row>
      </v-container>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" temporary v-if="mobile">
      <v-list nav>
        <v-list-item
          v-for="panel in paneles"
          :key="panel.value"
          :title="panel.title"
          class="py-0"
          @click="
            () => {
              panelActivo = panel.value
              drawer = false
            }
          "
        ></v-list-item>
        <v-menu :persistent="true" contained location="bottom" :offset="0" v-model="subMenu">
          <template v-slot:activator="{ props: menu }">
            <v-list-item title="Ingresar" v-bind="menu" :append-icon="subMenu ? 'mdi-menu-up' : 'mdi-menu-down'" />
          </template>
          <v-list
            :items="['Administrador', 'Cliente']"
            class="pa-0 elevation-0"
            style="margin-left: -3px; margin-right: 5px"
            nav
          >
            <template v-slot:item="{ props }">
              <Link :href="route('login', { tipo: props.title.toLowerCase() })" as="div">
                <v-list-item v-bind="props" tile class="border cursor-pointer"></v-list-item>
              </Link>
            </template>
          </v-list>
        </v-menu>
      </v-list>
    </v-navigation-drawer>
    <v-main>
      <v-container>
        <v-tabs-window v-model="panelActivo">
          <v-tabs-window-item value="nosotros">
            <v-row>
              <v-col cols="12">
                <v-img :width="400" class="mx-auto" :src="data.logo"></v-img>
              </v-col>
              <v-col cols="12" class="text-center">
                <div>
                  La COOAPASH es responsable del suministro de agua potable y red de alcantarillado sanitario del
                  conurbano formado por el municipios de Shinahota y sus áreas aledañas. Reafirmamos nuestra vocación de
                  servicio y trabajo; todas y todos con eficiencia y transparencia.
                </div>
              </v-col>
              <v-col cols="12" md="6">
                <v-sheet class="text-center rounded-lg border-lg" min-height="230">
                  <v-icon :size="100" icon="mdi-bullseye-arrow"></v-icon>
                  <div class="text-h6 font-weight-medium">Misión</div>
                  <div>
                    La COOAPASH es una entidad de Servicios Públicos Agua y Alcantarillado Shinahota, que garantiza y
                    protege los derechos de los usuarios y los operadores de servicio, coadyuvando al acceso a servicios
                    de calidad, sostenibles y precautelando la conservación de los recursos hídricos.
                  </div>
                </v-sheet>
              </v-col>
              <v-col cols="12" md="6">
                <v-sheet class="text-center rounded-lg border-lg" min-height="230">
                  <v-icon :size="100" icon="mdi-eye"></v-icon>
                  <div class="text-h6 font-weight-medium">Visión</div>
                  <div>
                    La COOAPASH se visualiza como un referente en la región como entidad gubernamental de regulación,
                    para garantizar el acceso, uso y disposición de los servicios de agua potable y saneamiento, con
                    enfoque de sostenibilidad ambiental, para el Vivir Bien de toda la población boliviana.
                  </div>
                </v-sheet>
              </v-col>
            </v-row>
          </v-tabs-window-item>
          <v-tabs-window-item value="servicios">
            <v-row>
              <v-col cols="12" class="text-center">
                <div class="text-h6 font-weight-bold">Servicios</div>
                <hr />
              </v-col>
              <v-col cols="12" md="6" lg="4">
                <v-sheet class="text-center rounded-lg border-lg" min-height="250">
                  <v-icon :size="100" icon="mdi-water"></v-icon>
                  <div class="text-h6 font-weight-medium">Agua potable</div>
                  <div>
                    Es el servicio que comprende desde la captación, conducción, tratamiento y almacenamiento de
                    recursos hídricos que son objeto de potabilización para luego llegar a nuestros usuarios a través
                    del sistema de distribución mediante redes de tuberías o medios alternativos.
                  </div>
                </v-sheet>
              </v-col>
              <v-col cols="12" md="6" lg="4">
                <v-sheet class="text-center rounded-lg border-lg" min-height="250">
                  <v-icon :size="100" icon="mdi-water-pump"></v-icon>
                  <div class="text-h6 font-weight-medium">Alcantarillado</div>
                  <div>
                    Es el servicio público que comprende la recolección, tratamiento y disposición de las Aguas
                    residuales en cuerpos receptores.
                  </div>
                </v-sheet>
              </v-col>
              <v-col cols="12" md="6" lg="4">
                <v-sheet class="text-center rounded-lg border-lg" min-height="250">
                  <v-icon :size="100" icon="mdi-laptop"></v-icon>
                  <div class="text-h6 font-weight-medium">Oficina virtual</div>
                  <div>
                    Servicio web que se provee a través de Internet para la consulta de deuda, impresión de recibos y el
                    pago de recibos pendientes.
                  </div>
                  <div>
                    <v-menu>
                      <template v-slot:activator="{ props }">
                        <v-btn color="primary" v-bind="props" variant="flat" class="mt-3"> Ingresar </v-btn>
                      </template>
                      <v-list :items="['Administrador', 'Cliente']" class="py-0">
                        <template v-slot:item="{ props }">
                          <Link :href="route('login', { tipo: props.title.toLowerCase() })" as="div">
                            <v-list-item class="subMenu" v-bind="props"></v-list-item>
                          </Link>
                        </template>
                      </v-list>
                    </v-menu>
                  </div>
                </v-sheet>
              </v-col>
            </v-row>
          </v-tabs-window-item>
          <v-tabs-window-item value="contacto">
            <v-row justify="center" align="center">
              <v-col cols="12" class="text-center">
                <div class="text-h6 font-weight-bold">Contactos</div>
                <hr />
              </v-col>
              <v-col cols="12" md="6">
                <v-sheet class="text-center rounded-lg border-lg" min-height="240">
                  <v-icon :size="100" icon="mdi-phone"></v-icon>
                  <div class="text-h6 font-weight-medium">Teléfono</div>
                  <div class="text-h5">
                    {{ data.telefono }}
                  </div>
                </v-sheet>
              </v-col>
              <v-col cols="12" md="6">
                <v-sheet class="text-center rounded-lg border-lg" min-height="240">
                  <v-icon :size="100" icon="mdi-map-marker"></v-icon>
                  <div class="text-h6 font-weight-medium">Dirección</div>
                  <div class="text-h6">
                    {{ data.departamento.nombre.toUpperCase() }}, {{ data.direccion.zona }},
                    {{ data.direccion.calle }}
                    #{{ data.direccion.numero }}
                  </div>
                </v-sheet>
              </v-col>
              <v-col cols="12" class="text-center">
                <div class="text-h6 font-weight-bold">Encuéntranos</div>
                <hr />
              </v-col>
              <v-col cols="12" md="6">
                <iframe
                  src="https://maps.google.com/maps?q=cooapash%20shinahota&amp;t=&amp;z=17&amp;ie=UTF8&amp;iwloc=&amp;output=embed"
                  width="100%"
                  height="480"
                  frameborder="0"
                  scrolling="no"
                  marginheight="0"
                  marginwidth="0"
                ></iframe>
              </v-col>
            </v-row>
          </v-tabs-window-item>
        </v-tabs-window>
      </v-container>
    </v-main>
  </v-app>
</template>

<style>
.subMenu {
  cursor: pointer;
}
.subMenu:hover {
  background-color: #efefef;
}
</style>
